import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  BoundedContent,
  Box,
  Button,
  Column,
  GridContainer,
  H2,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogContent,
  Row,
  tokens,
  tokensRaw,
} from '@vp/swan'
import { useTrack } from '../../../../utils/analytics'
import EditWrapper from '../../../../components/EditWrapper/EditWrapper'

const marketingVideoURL =
    'https://cms.cloudinary.vpsvc.com/video/upload/q_auto,f_auto:video/v1684152565/vista-logos-pages/en-US/VP01_-_Which_Vista_Logo_Service_is_Right_for_me_-_Final_Draft_-_LowRes.mp4#t=0.1'
const marketingThumbnailVideoURL =
    'https://cms.cloudinary.vpsvc.com/video/upload/q_auto:eco,f_auto:video,w_1400/v1684152665/vista-logos-pages/en-US/ThreeWaystoLogo_1920x1080.mp4#t=0.1'

const LoopVideo = styled.video`
    width: 100%;
    border-radius: ${tokens.SwanBaseBorderRadius400};
`

const IconContainer = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
    border-radius: ${tokens.SwanBaseBorderRadius200};
    background-color: ${tokens.SwanBaseColorWhite};
    opacity: 0.5;
    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        padding: 0;
        background-color: transparent;
    }
    &:hover {
        opacity: 1;
    }
`

const Video = styled.video`
    width: 100%;
    max-width: 1116px;
    border-radius: ${tokens.SwanBaseBorderRadius400};
    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        border-radius: 0;
    }
`

const StyledModalDialogContent = styled(ModalDialogContent)`
    max-width: 100%;
    background: transparent;
    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        top: 30%;
    }
`

const MarketingVideo = () => {
  const { t, i18n } = useTranslation()
  const track = useTrack()
  const [modalOpen, setModalOpen] = useState(false)

  const locale = i18n.language
  let defaultSubtitle = 'en-US.vtt'
  if (locale && locale.includes('fr')) {
    defaultSubtitle = 'fr-FR.vtt'
  } else if (locale && locale.includes('de')) {
    defaultSubtitle = 'de-DE.vtt'
  }

  const videoRef = useRef<HTMLVideoElement>(null)

  function closeModal () {
    setModalOpen(false)
    videoRef.current?.play()
  }

  function openModal () {
    setModalOpen(true)
    videoRef.current?.pause()
  }

  useEffect(
    function initVideoThumbnailIntersectionObserver () {
      if (!videoRef.current) {
        return
      }

      const observer = new IntersectionObserver(
        (entries) => {
          if (!videoRef.current) {
            return
          }
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const target = entry.target as HTMLVideoElement
              if (target.src === marketingThumbnailVideoURL) {
                return
              }
              target.src = marketingThumbnailVideoURL
              target.loop = true
              target.muted = true
              target.playsInline = true
              target.play()
            }
          })
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        }
      )
      observer.observe(videoRef.current)

      return () => {
        observer.disconnect()
      }
    },
    [videoRef]
  )

  const assetPathPrefix =
        typeof window !== 'undefined' && window.location.href.includes('vista-logos-web')
          ? ''
          : '/logo-design'

  return (
    <BoundedContent marginBottom='between-sections'>
      <EditWrapper url={t('Vista logos > marketing video.editUrl')} withImage>
        <GridContainer>
          <Row>
            <Column span={4}>
              <H2 fontSkin='title-section'>
                {t('Vista logos > marketing video.heading')}
              </H2>
            </Column>
            <Column span={8}>
              <Box
                onClick={() => {
                  track('Vista Logos Click Tracking', {
                    label: 'marketing-video',
                    location: 'bottom',
                  })
                  openModal()
                }}
              >
                <LoopVideo ref={videoRef} src='' />
                <IconContainer skin='unstyled'>
                  <Icon
                    iconType='play'
                    size='standard'
                    skin='standard'
                    alt={t('Vista logos > marketing video.image.altText')}
                  />
                </IconContainer>
              </Box>
            </Column>
          </Row>
        </GridContainer>
      </EditWrapper>
      <ModalDialog isOpen={modalOpen} onRequestDismiss={closeModal}>
        <StyledModalDialogContent fullBleed onClick={closeModal}>
          <ModalDialogBody>
            {modalOpen && (
              <Video
                src={marketingVideoURL}
                controls
                playsInline
                onClick={(e) => e.stopPropagation()}
              >
                <track
                  src={`${assetPathPrefix}/captions-marketing-video/${defaultSubtitle}`}
                  kind='captions'
                  srcLang={locale?.split('-')[0]}
                  label='Default'
                  default
                />
                <track
                  src={`${assetPathPrefix}/captions-marketing-video/en-US.vtt`}
                  kind='captions'
                  srcLang='en'
                  label='English'
                />
                <track
                  src={`${assetPathPrefix}/captions-marketing-video/de-DE.vtt`}
                  kind='captions'
                  srcLang='de'
                  label='German'
                />
                <track
                  src={`${assetPathPrefix}/captions-marketing-video/fr-FR.vtt`}
                  kind='captions'
                  srcLang='fr'
                  label='French'
                />
              </Video>
            )}
          </ModalDialogBody>
        </StyledModalDialogContent>
      </ModalDialog>
    </BoundedContent>
  )
}

export default MarketingVideo
