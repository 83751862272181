import type { ReactNode } from 'react'
import {
  BasicResponsiveImage,
  Button,
  Link,
  Pricing,
  PricingOffer,
  ProgressiveImage,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileFooter,
  StandardTileImage,
  StandardTileName,
  StandardTilePrice,
  tokens,
} from '@vp/swan'

interface LogoProductTileProps {
  title: string;
  description: string;
  imageSrc: string;
  altText: string;
  price: ReactNode;
  linkHref: string;
  ctaText: string;
  ctaRef: (node: HTMLElement) => void;
}

const LogoProductTile = ({
  title,
  description,
  imageSrc,
  altText,
  price,
  linkHref,
  ctaText,
  ctaRef,
}: LogoProductTileProps) => {
  return (
    <StandardTile skin='standard'>
      <StandardTileImage overflow='hidden'>
        <ProgressiveImage
          src={imageSrc}
          trigger='inView'
          intersectionOptions={{ threshold: 0 }}
        >
          {(image, isLoading) => (
            <BasicResponsiveImage
              aspectRatio={2 / 3}
              src={image.src}
              alt={altText}
              style={{
                filter: isLoading ? 'blur(20px)' : 'none',
                transition: 'filter 0.6s ease-out',
              }}
            />
          )}
        </ProgressiveImage>
      </StandardTileImage>
      <StandardTileContents>
        <StandardTileName
          fontSkin='title-subsection'
          style={{ marginBottom: tokens.SwanSemSpace3 }}
        >
          {title}
        </StandardTileName>
        <StandardTileDescription>{description}</StandardTileDescription>
        <StandardTilePrice>
          <Pricing>
            <PricingOffer>{price}</PricingOffer>
          </Pricing>
        </StandardTilePrice>
      </StandardTileContents>
      <StandardTileFooter>
        <Button
          skin='secondary'
          render={(props) => (
            <Link href={linkHref} className={props.className} ref={ctaRef}>
              {props.children}
            </Link>
          )}
        >
          {ctaText}
        </Button>
      </StandardTileFooter>
    </StandardTile>
  )
}

export default LogoProductTile
