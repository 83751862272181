import type { TFunction } from 'i18next'

export type BusinessToolKey =
    | 'businessNameGenerator'
    | 'brandKit'
    | 'wixWebsites'
    | 'socialMediaTemplates'
    | 'design'
    | 'logomaker'

interface BusinessTool {
  description: string;
  url: string;
  image: {
    src: string;
    altText: string;
  };
}

export function getLaunchMyBusinessToolsConfig (
  businessTools: BusinessToolKey[],
  t: TFunction
): (BusinessTool & { businessToolKey: BusinessToolKey })[] {
  const launchMyBusinessToolsConfig: Record<BusinessToolKey, BusinessTool> = {
    businessNameGenerator: {
      description: t(
        'Vista Logos > Launch My Business Tools > Business Name Generator.productTileHeader'
      ),
      url: t(
        'Vista Logos > Launch My Business Tools > Business Name Generator.url.contentfulUrl'
      ),
      image: {
        src: t(
          'Vista Logos > Launch My Business Tools > Business Name Generator.productImage.src'
        ),
        altText: t(
          'Vista Logos > Launch My Business Tools > Business Name Generator.productImage.altText'
        ),
      },
    },
    brandKit: {
      description: t('Vista Logos > Launch My Business Tools > Brand Kit.productTileHeader'),
      url: t('Vista Logos > Launch My Business Tools > Brand Kit.url.contentfulUrl'),
      image: {
        src: t('Vista Logos > Launch My Business Tools > Brand Kit.productImage.src'),
        altText: t(
          'Vista Logos > Launch My Business Tools > Brand Kit.productImage.altText'
        ),
      },
    },
    wixWebsites: {
      description: t(
        'Vista Logos > Launch My Business Tools > Vista x Wix Websites.productTileHeader'
      ),
      url: t(
        'Vista Logos > Launch My Business Tools > Vista x Wix Websites.url.contentfulUrl'
      ),
      image: {
        src: t(
          'Vista Logos > Launch My Business Tools > Vista x Wix Websites.productImage.src'
        ),
        altText: t(
          'Vista Logos > Launch My Business Tools > Vista x Wix Websites.productImage.altText'
        ),
      },
    },
    socialMediaTemplates: {
      description: t(
        'Vista Logos > Launch My Business Tools > Social Media Templates.productTileHeader'
      ),
      url: t(
        'Vista Logos > Launch My Business Tools > Social Media Templates.url.contentfulUrl'
      ),
      image: {
        src: t(
          'Vista Logos > Launch My Business Tools > Social Media Templates.productImage.src'
        ),
        altText: t(
          'Vista Logos > Launch My Business Tools > Social Media Templates.productImage.altText'
        ),
      },
    },
    design: {
      description: t(
        'Vista Logos > Launch My Business Tools > Design Services.productTileHeader'
      ),
      url: t('Vista Logos > Launch My Business Tools > Design Services.url.contentfulUrl'),
      image: {
        src: t('Vista Logos > Launch My Business Tools > Design Services.productImage.src'),
        altText: t(
          'Vista Logos > Launch My Business Tools > Design Services.productImage.altText'
        ),
      },
    },
    logomaker: {
      description: t('Vista Logos > Launch My Business Tools > Logomaker.productTileHeader'),
      url: t('Vista Logos > Launch My Business Tools > Logomaker.url.contentfulUrl'),
      image: {
        src: t('Vista Logos > Launch My Business Tools > Logomaker.productImage.src'),
        altText: t(
          'Vista Logos > Launch My Business Tools > Logomaker.productImage.altText'
        ),
      },
    },
  }
  return businessTools.map((businessToolKey) => ({
    businessToolKey,
    ...launchMyBusinessToolsConfig[businessToolKey],
  }))
}
