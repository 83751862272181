import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import xss from 'xss'
import { useLogger } from '@vp/ubik-context'
import { Logger } from '@vp/ubik-logging'
import styled from 'styled-components'
import type { ReactNode } from 'react'
import { Column, FlexBox, GridContainer, H2, Row, Spinner, tokens, Typography } from '@vp/swan'
import { useAuthContext } from '../../auth'
import { DEFAULT_APP_LOCALE } from '../../i18n/config'
import { useEnvContext } from '../../utils/environmentContext'
import { getDesignableProductPrice, priceFromFloat } from '../../utils/pricing'
import LogoProductTile from './LogoProductTile'
import { useLogoProductTilesConfig } from './LogoProductTilesConfig'
import type { Locale } from '../../../@types/locale'
import type { LogoProductKey } from './LogoProductTilesConfig'

const LogoProductTilesContainer = styled.div`
    /* @ts-ignore - TypeScript currently does not support container queries yet */
    /* The container-query adjusts for narrower containers like the BNG results modal (BusinessNameModal) */
    container-name: logo-product-tiles;
    container-type: inline-size;
`

const ResponsiveFlexBox = styled(FlexBox)`
    gap: ${tokens.SwanSemSpace4};

    /* Max width set to 425px for the mobile view breakpoint */
    /* At 425px and below, we switch to a column layout to better fit the mobile view */
    /* @ts-ignore - TypeScript does not support the @container rule yet */
    @container logo-product-tiles (max-width: 425px) {
        flex-direction: column;
    }
`

const ResponsiveColumn = styled(Column)`
    /* Full width set inside narrower container for BNG results modal */
    /* @ts-ignore - TypeScript does not support the @container rule yet */
    @container logo-product-tiles (max-width: 425px) {
        width: 100% !important;
    }
`

interface LogoProductTilesProps {
  showLogomaker?: boolean;
  showLogoContest?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  trackingCategory: string;
  customProductTileCopy?: {
    [key in LogoProductKey]?: {
      title?: string;
      description?: string;
    };
  };
}

const LogoProductTiles = ({
  showLogomaker = true,
  showLogoContest = true,
  title,
  description,
  trackingCategory,
  customProductTileCopy,
}: LogoProductTilesProps) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const currentCulture = locale || DEFAULT_APP_LOCALE
  const { anonymousId } = useAuthContext()
  const logger : Logger = useLogger()
  // NOTE: Only Bifrost enabled locales are currently sent to /design/logo-essential.
  // All other locales are sent to 99designs eg:
  // https://99designs.de/logo-essential-and-consultation/details
  const isBifrostEnabled = currentCulture === 'en-AU' || currentCulture === 'en-US'

  const [logoEssentialListPrice, setLogoEssentialListPrice] = useState<string>('')
  const [isLoadingPrice, setIsLoadingPrice] = useState<boolean>(false)

  const logoProductTileMap = useLogoProductTilesConfig(
    trackingCategory,
    currentCulture as Locale
  )

  const { ENV_API_BASE_URL } = useEnvContext()

  useEffect(() => {
    const fetchLogoEssentialPrice = async () => {
      setIsLoadingPrice(true)
      const price = await getDesignableProductPrice('logoessential', currentCulture, ENV_API_BASE_URL, logger)
      const listPrice = price
        ? priceFromFloat(price.list_price.untaxed, currentCulture as Locale)
        : ''
      setLogoEssentialListPrice(listPrice)
      setIsLoadingPrice(false)
    }

    if (isBifrostEnabled) {
      fetchLogoEssentialPrice()
    }
  }, [currentCulture])

  const appendTrackingParams = (key: LogoProductKey, linkHref: string) => {
    if (!anonymousId) return linkHref
    return (key === 'logo-essential' && !isBifrostEnabled) || key === 'logo-contest'
      ? `${linkHref}?utm_source=partner&utm_medium=referral&utm_campaign=VistaLogos&vistaprint_aid=${anonymousId}`
      : linkHref
  }

  const defaultDescription = (
    <div
      dangerouslySetInnerHTML={{
        __html: xss(
          t('Vista Logos > Logo Product Tiles.sectionSubHeader', {
            logoInspirationRootLink: 'https://www.vistaprint.com/logomaker/ideas',
          }) as string
        ),
      }}
    />
  )

  return (
    <LogoProductTilesContainer>
      <H2 fontSkin='title-section' as='p'>
        {title ?? t('Vista Logos > Logo Product Tiles.sectionHeader')}
      </H2>
      <Typography fontSkin='body-standard' marginBottom='between-subsections'>
        {description ?? defaultDescription}
      </Typography>
      <ResponsiveFlexBox>
        <GridContainer>
          <Row>
            {Object.entries(logoProductTileMap)
              .filter(
                ([key]) =>
                  (key !== 'logomaker' || showLogomaker) &&
                  (key !== 'logo-contest' || showLogoContest)
              )
              .map(([key, productTile]) => {
                const {
                  title,
                  description,
                  imageDefault,
                  linkHref,
                  ctaText,
                  priceText,
                  ctaRef,
                } = productTile

                const customCopy = customProductTileCopy?.[key as LogoProductKey]
                return (
                  <ResponsiveColumn key={key} span={4}>
                    <LogoProductTile
                      title={customCopy?.title ?? title}
                      description={customCopy?.description ?? description}
                      imageSrc={imageDefault.src}
                      altText={imageDefault.altText}
                      price={
                        key === 'logo-essential'
                          ? (
                              isLoadingPrice
                                ? (
                                  <Spinner
                                    size='tiny'
                                    accessibleText='Loading price'
                                  />
                                  )
                                : isBifrostEnabled
                                  ? (
                                      logoEssentialListPrice
                                    )
                                  : (
                                      priceText
                                    )
                            )
                          : (
                              priceText
                            )
                      }
                      linkHref={appendTrackingParams(
                        key as LogoProductKey,
                        linkHref
                      )}
                      ctaText={ctaText}
                      ctaRef={ctaRef}
                    />
                  </ResponsiveColumn>
                )
              })}
          </Row>
        </GridContainer>
      </ResponsiveFlexBox>
    </LogoProductTilesContainer>
  )
}

export default LogoProductTiles
