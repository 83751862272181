import { useTranslation } from 'react-i18next'
import { useUserContext } from '@vp/ubik-context'
import { availableBNGLocales } from '../../../@types/locale'
import LogoProductTiles from '../../components/LogoProductTiles/LogoProductTiles'
import { BoundedContent } from '@vp/swan'
import { useTrackPage } from '../../utils/analytics'
import BusinessCardPromoBanner from '../../components/BusinessCardPromoBanner/BusinessCardPromoBanner'
import LaunchMyBusinessTools from '../../components/LaunchMyBusinessTools/LaunchMyBusinessTools'
import CustomerStory from './components/CustomerStory/CustomerStory'
import DigitalMarketing from './components/DigitalMarketing/DigitalMarketing'
import Faq from './components/Faq/Faq'
import MainBanner from './components/MainBanner/MainBanner'
import MarketingVideo from './components/MarketingVideo/MarketingVideo'
import type { Locale } from '../../../@types/locale'

const LogoDesignPage = () => {
  const { i18n } = useTranslation()
  const userContext = useUserContext()
  const locale = i18n.language
  const isAffiliateTraffic = userContext?.queryStrings?.utm_medium === 'affiliate'

  // TODO: We currently don't pass a page name in production either...
  useTrackPage('')

  return (
    <>
      <MainBanner />
      <BoundedContent marginY='between-sections'>
        <LogoProductTiles
          trackingCategory='Design Services'
          showLogoContest={!isAffiliateTraffic}
        />
      </BoundedContent>
      <BusinessCardPromoBanner
        ctaUrl='/logomaker'
        locale={locale as Locale}
        trackingCategory='Design Services'
        applyMargin
      />
      <MarketingVideo />
      {availableBNGLocales.includes(locale as Locale)
        ? (
          <LaunchMyBusinessTools
            header='Got a new business idea? We’ve got you covered.'
            subheader='From naming your business to seeing it on a slick website, turn your idea into a reality with our digital and design tools. Start creating your business essentials all in one place so you can hit the ground running.'
            businessTools={[
              'businessNameGenerator',
              'brandKit',
              'wixWebsites',
              'socialMediaTemplates',
              'design',
            ]}
            trackingCategory='Design Services'
          />
          )
        : (
          <DigitalMarketing />
          )}
      <CustomerStory />
      <Faq />
    </>
  )
}

export default LogoDesignPage
