import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  BasicResponsiveImage,
  BoundedContent,
  Callout,
  Carousel,
  CarouselSlide,
  H2,
  Link,
  P,
  StandardTile,
  StandardTileContents,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
} from '@vp/swan'
import { useTrackNavigationClicked } from '../../utils/analytics'
import { getLaunchMyBusinessToolsConfig } from './LaunchMyBusinessToolsConfig'
import type { BusinessToolKey } from './LaunchMyBusinessToolsConfig'

interface BusinessToolTileProps {
  id: string;
  description: string;
  url: string;
  imageSrc: string;
  altText: string;
  trackingCategory: string;
}

// Align bottom of Tiles
const StyledLink = styled(Link)`
    height: 100%;
`

const BusinessToolTile = ({
  id,
  description,
  url,
  imageSrc,
  altText,
  trackingCategory,
}: BusinessToolTileProps) => {
  const { t } = useTranslation()
  const trackingRef = useTrackNavigationClicked(
    trackingCategory,
    'Launch My Business Tools',
    url,
    id
  )

  return (
    <StandardTile
      skin='product'
      imageWidth='full-bleed'
      render={(props) => (
        <StyledLink
          className={props.className}
          href={url}
          ref={trackingRef}
          skin='unstyled'
        >
          {props.children}
        </StyledLink>
      )}
    >
      {id === 'businessNameGenerator' && (
        <StandardTileOverlay>
          <Callout skin='accent'>
            {t('Vista Logos > Launch My Business Tools > Callout > New.value')}
          </Callout>
        </StandardTileOverlay>
      )}
      <StandardTileImage>
        <BasicResponsiveImage aspectRatio={1} src={imageSrc} alt={altText} />
      </StandardTileImage>
      <StandardTileContents>
        <StandardTileName>{description}</StandardTileName>
      </StandardTileContents>
    </StandardTile>
  )
}

interface LaunchMyBusinessToolsProps {
  header: string;
  subheader: string;
  businessTools: BusinessToolKey[];
  trackingCategory: string;
}

const LaunchMyBusinessTools = ({
  header,
  subheader,
  businessTools,
  trackingCategory,
}: LaunchMyBusinessToolsProps) => {
  const { t } = useTranslation()
  const launchMyBusinessTools = getLaunchMyBusinessToolsConfig(businessTools, t)

  return (
    <BoundedContent marginY='between-sections'>
      <H2 fontSkin='title-section'>{header}</H2>
      <P>{subheader}</P>
      <Carousel
        slidesToShow={{ xs: 2, md: 4, lg: 5, xl: 5 }}
        gridGutters
        accessibleTextForPrevious={t(
          'Vista Logos > Carousel > Previous Slide aria-label.value'
        )}
        accessibleTextForNext={t('Vista Logos > Carousel > Next Slide aria-label.value')}
        marginTop='between-subsections'
      >
        {launchMyBusinessTools.map(({ businessToolKey, description, url, image }) => (
          <CarouselSlide key={businessToolKey}>
            <BusinessToolTile
              id={businessToolKey}
              description={description}
              url={url}
              imageSrc={image.src}
              altText={image.altText}
              trackingCategory={trackingCategory}
            />
          </CarouselSlide>
        ))}
      </Carousel>
    </BoundedContent>
  )
}

export default LaunchMyBusinessTools
