import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import type { MouseEvent } from 'react'
import {
  Banner,
  BannerDescription,
  BannerEyebrow,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  Button,
  FluidImage,
  ModalDialog,
  ModalDialogBody,
  ModalDialogContent,
  tokens,
  tokensRaw,
  Typography,
} from '@vp/swan'
import { useTrack } from '../../../../utils/analytics'
import EditWrapper from '../../../../components/EditWrapper/EditWrapper'

const ModalContainer = styled(ModalDialogContent)`
    max-width: fit-content;
    max-height: fit-content;
    overflow: hidden;
    background: transparent;

    @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointSmEnd}) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        max-width: 100%;
        min-height: fit-content;
        height: fit-content;
    }
`

const Video = styled.video`
    height: 628px;
    width: 1116px;
    border-radius: ${tokens.SwanBaseBorderRadius400};

    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
        width: 1000px;
        height: auto;
    }

    @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointSmEnd}) {
        height: auto;
        width: 100vw;
        border-radius: 0;
    }
`

const CustomerStory = () => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const track = useTrack()

  return (
    <>
      <Banner darkMode bgc='midnight'>
        <BannerTextContainer>
          <BannerText>
            <EditWrapper url={t('Vista logos > customer story.editUrl')}>
              <BannerEyebrow>
                {t('Vista logos > customer story.heading')}
              </BannerEyebrow>
              <BannerTitle>
                {t('Vista logos > customer story.subheading')}
              </BannerTitle>
              <BannerDescription>
                <p>{t('Vista logos > customer story.description')}</p>
                <Typography fontSkin='footnote'>
                  {t('Vista logos > customer story.footnote2')}
                </Typography>
              </BannerDescription>
              {t('Vista logos > customer story.callToAction1Text') !== '-1' && (
                <BannerLinks>
                  <Button
                    skin='primary'
                    onClick={() => {
                      track('Vista Logos Click Tracking', {
                        label: 'customer-story',
                        location: 'bottom',
                      })
                      setModalOpen(true)
                    }}
                  >
                    {t('Vista logos > customer story.callToAction1Text')}
                  </Button>
                </BannerLinks>
              )}
            </EditWrapper>
          </BannerText>
        </BannerTextContainer>
        <BannerImageContainer>
          <BannerImage>
            <EditWrapper
              url={t('Vista logos > customer story video.editUrl')}
              withImage
            >
              <FluidImage
                src={t('Vista logos > customer story.image.src')}
                alt={t('Vista logos > customer story.image.altText')}
              />
            </EditWrapper>
          </BannerImage>
        </BannerImageContainer>
      </Banner>
      <ModalDialog
        isOpen={modalOpen}
        onRequestDismiss={() => {
          setModalOpen(false)
        }}
      >
        <ModalContainer fullBleed onClick={() => setModalOpen(false)}>
          <ModalDialogBody>
            {modalOpen && (
              <Video
                controls
                playsInline
                onClick={(event: MouseEvent<HTMLVideoElement>) => {
                  event.stopPropagation()
                }}
              >
                <source
                  src='https://cms.cloudinary.vpsvc.com/video/upload/w_1200,c_limit,f_auto,q_auto:good/MXP5803-VistaTV-LA-RachelMadeStudios-Edit-001.webm#t=0.1'
                  type='video/webm'
                />
                <source
                  src='https://cms.cloudinary.vpsvc.com/video/upload/w_1200,c_limit,f_auto,q_auto:good/MXP5803-VistaTV-LA-RachelMadeStudios-Edit-001.mp4#t=0.1'
                  type='video/mp4'
                />
                <source
                  src='https://cms.cloudinary.vpsvc.com/video/upload/w_1200,c_limit,f_auto,q_auto:good/MXP5803-VistaTV-LA-RachelMadeStudios-Edit-001.mov#t=0.1'
                  type='video/mov'
                />
              </Video>
            )}
          </ModalDialogBody>
        </ModalContainer>
      </ModalDialog>
    </>
  )
}

export default CustomerStory
