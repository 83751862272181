import { useTranslation } from 'react-i18next'
import {
  Banner,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  FluidImage,
  responsive,
} from '@vp/swan'
import EditWrapper from '../../../../components/EditWrapper/EditWrapper'

const ResponsiveBanner = responsive(Banner)

const MainBanner = () => {
  const { t } = useTranslation()

  return (
    <ResponsiveBanner
      variant='full-width-image'
            // TODO: imageFocalPoint on Banner is deprecated but does not yet support responsive props
      xs={{ imageFocalPoint: 'right center' }}
      md={{ imageFocalPoint: 'center center' }}
    >
      <BannerTextContainer>
        <BannerText>
          <EditWrapper url={t('Vista logos > home banner.editUrl')}>
            <BannerTitle as='h1'> {t('Vista logos > home banner.heading')}</BannerTitle>
            <BannerDescription as='p'>
              {t('Vista logos > home banner.subheading')}
            </BannerDescription>
          </EditWrapper>
        </BannerText>
      </BannerTextContainer>
      <BannerImageContainer>
        <BannerImage>
          <FluidImage
            src={t('Vista logos > home banner.image.src')}
            alt={t('Vista logos > home banner.image.altText')}
          />
        </BannerImage>
      </BannerImageContainer>
    </ResponsiveBanner>
  )
}

export default MainBanner
