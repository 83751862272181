import {
  BasicResponsiveImage,
  Button,
  Link,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileFooter,
  StandardTileImage,
  StandardTileName,
  tokens,
} from '@vp/swan'

type ImageCardProps = {
  title: string;
  description: string;
  image: string;
  buttonText: string;
  href: string;
  linkRef: (node: HTMLElement) => void;
}

const ImageCard = ({ title, description, image, buttonText, href, linkRef }: ImageCardProps) => {
  return (
    <StandardTile skin='product' imageWidth='full-bleed'>
      <StandardTileImage style={{ backgroundColor: tokens.SwanSemColorBrandWarmWhite }}>
        <BasicResponsiveImage aspectRatio={1} src={image} alt={title} />
      </StandardTileImage>
      <StandardTileContents>
        <StandardTileName>{title}</StandardTileName>
        <StandardTileDescription>{description}</StandardTileDescription>
        <StandardTileFooter>
          <Button
            skin='secondary'
            render={(props) => (
              <Link className={props.className} ref={linkRef} href={href}>
                {props.children}
              </Link>
            )}
          >
            {buttonText}
          </Button>
        </StandardTileFooter>
      </StandardTileContents>
    </StandardTile>
  )
}

export default ImageCard
