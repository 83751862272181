import { CSSProperties, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import useIsEditMode from '../../utils/useIsEditMode'

type EditWrapperProps = {
  url: string;
  children: React.ReactNode;
  withImage?: boolean;
  style?: CSSProperties;
}

const hoverStyles = css`
    text-decoration: none;
    border: 1px solid #00ff00;
    color: inherit;
    cursor: pointer;
`

const StyledATag = styled.a<{ withImage: boolean }>`
    text-decoration: none;
    display: block;

    &:hover {
        ${hoverStyles}

        ${(props) =>
            props.withImage &&
            css`
                span {
                    ${hoverStyles}
                }
            `}
    }
`

const EditWrapper = ({ url, children, withImage = false, style }: EditWrapperProps) => {
  const isEditMode = useIsEditMode()
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (isEditMode && isClient) {
    return (
      <StyledATag
        withImage={withImage}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          window.open(url, '_blank')
        }}
        style={style}
        target='_blank'
        href={url}
        rel='noopener noreferrer'
      >
        {children}
      </StyledATag>
    )
  }

  return <>{children}</>
}

export default EditWrapper
