import { useTranslation } from 'react-i18next'
import { marked } from 'marked'
import xss from 'xss'
import { BoundedContent, tokensRaw, Typography } from '@vp/swan'
import EditWrapper from '../../../../components/EditWrapper/EditWrapper'
import FAQAccordion from '../../../../components/FAQAccordion/FAQAccordion'
import type { FAQ } from '../../../../components/FAQAccordion/FAQAccordion'

const Faq = () => {
  const { t } = useTranslation()

  const faqData = [
    {
      id: 'faq-1',
      question: t('Vista logos > faq 1.question'),
      answer: t('Vista logos > faq 1.answer'),
    },
    {
      id: 'faq-2',
      question: t('Vista logos > faq 2.question'),
      answer: t('Vista logos > faq 2.answer'),
    },
    {
      id: 'faq-3',
      question: t('Vista logos > faq 3.question'),
      answer: t('Vista logos > faq 3.answer'),
    },
    {
      id: 'faq-4',
      question: t('Vista logos > faq 4.question'),
      answer: t('Vista logos > faq 4.answer'),
    },
    {
      id: 'faq-5',
      question: t('Vista logos > faq 5.question'),
      answer: t('Vista logos > faq 5.answer'),
    },
    {
      id: 'faq-6',
      question: t('Vista logos > faq 6.question'),
      answer: t('Vista logos > faq 6.answer'),
    },
    {
      id: 'faq-7',
      question: t('Vista logos > faq 7.question'),
      answer: t('Vista logos > faq 7.answer'),
    },
    {
      id: 'faq-8',
      question: t('Vista logos > faq 8.question'),
      answer: t('Vista logos > faq 8.answer'),
    },
  ]

  const convertMarkdownToHTML = (faqData: FAQ[]): FAQ[] =>
    faqData.map((faq) => ({
      ...faq,
      answer: (
        <div
          dangerouslySetInnerHTML={{
            __html: xss(marked.parse(faq.answer as string) as string),
          }}
        />
      ),
    }))

  return (
    <BoundedContent marginY='between-sections'>
      <EditWrapper url={t('Vista logos > faq heading.editUrl')}>
        <Typography
          fontSkin='title-section'
          style={{ marginBottom: tokensRaw.SwanCompHeadingSpaceBelowHeading }}
        >
          {t('Vista logos > faq heading.value')}
        </Typography>
      </EditWrapper>
      <FAQAccordion faqData={convertMarkdownToHTML(faqData)} />
    </BoundedContent>
  )
}

export default Faq
