import { useLogger, useUserContext } from '@vp/ubik-context'
import type { Logger } from '@vp/ubik-logging'
import { useTranslation } from 'react-i18next'
import LogoDesignPage from '../app/pages/LogoDesignPage'
import { App } from '../app'
import { DefaultPageTrackingParams } from '../app/utils/analytics'
import type { Translation } from '../@types/resources'
import type { Locale } from '../@types/locale'
import type { EnvironmentContextType } from '../app/utils/environmentContext'

export interface Props {
  translation: Translation
  envContext: EnvironmentContextType
}

const defaultPageTrackingParams: DefaultPageTrackingParams = {
  product_id: 'vista-logos',
  core_product_version: 1,
  route: 'logo-design',
  name: 'Vista Logos',
}

export const Fragment = ({ translation, envContext }: Props) => {
  const logger: Logger = useLogger()
  const { i18n } = useTranslation()
  const locale = useUserContext().locale.split('-')
    .map((part, index) => (index === 0 ? part.toLowerCase() : part.toUpperCase()))
    .join('-')

  logger.log('Rendering fragment for the locale', locale)

  i18n
    .addResourceBundle(locale, 'translation', translation)
    .changeLanguage(locale)

  return (
    <App defaultPageTrackingParams={defaultPageTrackingParams} locale={locale as Locale} envContext={envContext}><LogoDesignPage /></App>
  )
}
