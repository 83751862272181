import type { Locale } from '../../@types/locale'
import type { Logger } from '@vp/ubik-logging'

type Price = {
  currency: string;
  list_price: {
    taxed: number;
    untaxed: number;
  };
}

type DesignableProduct = {
  price: Price;
  service_product: {
    mpv_id: string;
    product_key: string;
    name: string;
    pim_merchant_version: number;
  };
}

type LocaleCurrencyMap = { [key in Locale]: string }

const localeCurrencyMap: LocaleCurrencyMap = {
  'en-US': 'USD',
  'en-AU': 'AUD',
  'en-GB': 'GBP',
  'fr-FR': 'EUR',
  'fr-CA': 'CAD',
  'fr-CH': 'CHF',
  'de-DE': 'EUR',
  'en-CA': 'CAD',
  'en-NZ': 'NZD',
  'en-SG': 'SGD',
  'en-IE': 'EUR',
  'de-AT': 'EUR',
  'de-CH': 'CHF',
  'en-IN': 'INR',
  'it-IT': 'EUR',
  'nl-NL': 'EUR',
  'nl-BE': 'EUR',
  'fr-BE': 'EUR',
  'es-ES': 'EUR',
  'es-US': 'USD',
  'it-CH': 'CHF',
  'pt-PT': 'EUR',
  'nb-NO': 'NOK',
  'da-DK': 'DKK',
  'sv-SE': 'SEK',
  'fi-FI': 'EUR',
}

export const priceFromFloat = (price: number, locale: Locale): string => {
  const currency = localeCurrencyMap[locale]
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
  }).format(price)
}

export const getDesignableProductPrice = async (
  mpvId: string,
  culture: string,
  apiBaseUrl: string,
  logger: Logger
): Promise<Price | null> => {
  const apiURL = `${apiBaseUrl}/api/get-designable-product`

  try {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        culture,
        mpvId,
      }),
    }
    const response = await fetch(apiURL, options)

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`)
    }

    const data: DesignableProduct = await response.json()
    return data.price
  } catch (error: any) {
    logger.error({
      name: `Failed to fetch designable product price from ${apiURL}`,
      message: `Error fetching mpvId: ${mpvId} for culture: ${culture}. Error: ${error.message}`,
    })
    return null
  }
}
