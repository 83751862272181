export type Locale =
    | 'en-US'
    | 'en-AU'
    | 'en-GB'
    | 'fr-FR'
    | 'fr-CA'
    | 'fr-CH'
    | 'de-DE'
    | 'en-CA'
    | 'en-NZ'
    | 'en-SG'
    | 'en-IE'
    | 'de-AT'
    | 'de-CH'
    | 'en-IN'
    | 'it-IT'
    | 'nl-NL'
    | 'nl-BE'
    | 'fr-BE'
    | 'es-ES'
    | 'es-US'
    | 'it-CH'
    | 'pt-PT'
    | 'nb-NO'
    | 'da-DK'
    | 'sv-SE'
    | 'fi-FI'

// todo - remove this once file pages/business-name-generator/index.tsx is migrated or use from here in all places
export const availableBNGLocales: Locale[] = [
  'en-US',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-AU',
  'en-NZ',
  'en-SG',
]
