import {
  Banner,
  BannerDescription,
  BannerFootnote,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  Button,
  FluidImage,
  Link,
} from '@vp/swan'
import { useTrackNavigationClicked } from '../../utils/analytics'
import type { Locale } from '../../../@types/locale'

interface BusinessCardPromoBannerProps {
  ctaUrl: string;
  locale: Locale;
  trackingCategory: string;
  applyMargin?: boolean;
}

const isBusinessCardPromoLive = (locale: Locale) => {
  const currentTime = new Date().getTime()
  if (locale === 'en-US' || locale === 'en-CA') {
    const startTime = new Date('2025-01-01T00:00-05:00').getTime()
    const endTime = new Date('2025-04-18T00:00-00:00').getTime()

    return currentTime >= startTime && currentTime <= endTime
  }
  if (locale === 'en-GB') {
    const startTime = new Date('2025-04-01T16:00+11:00').getTime()
    const endTime = new Date('2025-06-30T00:00-00:00').getTime()

    return currentTime >= startTime && currentTime <= endTime
  }
  return false
}

const BusinessCardPromoBanner = ({
  ctaUrl,
  locale,
  trackingCategory,
  applyMargin,
}: BusinessCardPromoBannerProps) => {
  const ctaRef = useTrackNavigationClicked(
    trackingCategory,
    'logoAndBusinessCardPromoBanner',
    ctaUrl,
    'createAFreeLogo'
  )

  let title = 'Your logo, in your hands – for $10'
  let description = 'Create a free logo and print it on 50 standard business cards for just $10.'
  let footnote = 'Promo code issued at download. Offer expires April 18th.'

  if (locale === 'en-GB') {
    title = 'Print your new logo with 20% off'
    description = 'Create a free logo and get 20% off your new business cards.'
    footnote =
        'Promo code issued at download. Offer expires 30 June.'
  }

  if (isBusinessCardPromoLive(locale)) {
    return (
      <Banner height='short' bgc='midnight' darkMode textBoxHorizontalAlign='right' as='section' marginY={applyMargin ? 'between-sections' : '0'}>
        <BannerTextContainer>
          <BannerText>
            <BannerTitle>{title}</BannerTitle>
            <BannerDescription as='p'>{description}</BannerDescription>
            <BannerLinks>
              <Button
                skin='primary'
                render={(props) => (
                  <Link href={ctaUrl} className={props.className} ref={ctaRef}>
                    {props.children}
                  </Link>
                )}
              >
                Create a free logo
              </Button>
            </BannerLinks>
            <BannerFootnote>{footnote}</BannerFootnote>
          </BannerText>
        </BannerTextContainer>
        <BannerImageContainer>
          <BannerImage imageFocalPoint='center center'>
            <FluidImage
              src='https://cms.cloudinary.vpsvc.com/image/upload/v1741223530/vista-logos-pages/en-US/logomaker/LM_BC_Promo_banner_920x500.webp'
              alt={
                            'A close-up of two hands holding a vibrant purple business card showcasing the "hype" logo with an arrow and the text "Your free logo here." Beside the card, a graphic displays the "hype" logo in various color options, representing the DIY logo maker tool.'
                        }
            />
          </BannerImage>
        </BannerImageContainer>
      </Banner>
    )
  }
}

export default BusinessCardPromoBanner
