import { isValidElement } from 'react'
import { useUserContext } from '@vp/ubik-context'
import { renderToStaticMarkup } from 'react-dom/server'
import xss from 'xss'
import type { ReactElement, ReactNode } from 'react'
import { Accordion, BasicCollapsible } from '@vp/swan'
import { useTrack } from '../../utils/analytics'
import { removePathQueryParams } from '../../utils/removePathQueryParams'

export type FAQ = {
  id?: string;
  question: ReactElement | string;
  answer: ReactNode;
}

interface FAQAccordionProps {
  faqData: FAQ[];
}

const FAQAccordion = ({ faqData }: FAQAccordionProps) => {
  const track = useTrack()
  const pathName = useUserContext().forwardedPath || ''
  const currentPagePath = removePathQueryParams(pathName)

  const trackFAQClick = (faqId: string) => {
    track('Navigation Clicked', {
      label: 'Content:FAQs',
      eventDetail: `${currentPagePath};${currentPagePath};FAQs;${faqId}`,
      navigationDetail: faqId,
    })
  }

  const processFaqInputForSchema = (faqInput: ReactNode): string => {
    if (typeof faqInput === 'string') {
      return faqInput
    }
    if (isValidElement(faqInput)) {
      // Convert react node to static html string
      const rawHtml = renderToStaticMarkup(faqInput)

      // Remove html tags not needed in schema
      return xss(rawHtml, {
        whiteList: {
          a: ['href'],
          b: [],
          ul: [],
          ol: [],
          li: [],
        },
        stripIgnoreTag: true,
      })
    }
    return ''
  }

  const generateFAQSchema = (faqData: FAQ[]) => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqData.map((faqData) => {
      return {
        '@type': 'Question',
        name: processFaqInputForSchema(faqData.question),
        acceptedAnswer: {
          '@type': 'Answer',
          text: processFaqInputForSchema(faqData.answer),
        },
      }
    }),
  })

  return (
    <Accordion>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(generateFAQSchema(faqData)) }}
      />
      {faqData.map(({ id, question, answer }, index) => (
        <BasicCollapsible
          key={`faq-${index}`}
          collapsibleId={`faq-${index}`}
          heading={question}
          onClick={() => trackFAQClick(id || `faq-${index}`)}
        >
          {answer}
        </BasicCollapsible>
      ))}
    </Accordion>
  )
}

export default FAQAccordion
