import { useTranslation } from 'react-i18next'
import { useTrackNavigationClicked } from '../../utils/analytics'
import type { Locale } from '../../../@types/locale'

export type LogoProductKey = 'logomaker' | 'logo-essential' | 'logo-contest'

type LogoProductTile = {
  title: string;
  description: string;
  imageDefault: { src: string; altText: string };
  linkHref: string;
  ctaText: string;
  priceText?: string;
  ctaRef: any;
}

type LogoProductTilesConfig = Record<LogoProductKey, LogoProductTile>

type LogoProductsPricing = {
  [key in Locale]: {
    'logo-essential': string;
    'logo-contest': string;
  };
}

export const logoProductsPricing: LogoProductsPricing = {
  'en-US': {
    'logo-essential': '',
    'logo-contest': '$299',
  },
  'en-AU': {
    'logo-essential': '',
    'logo-contest': '$449',
  },
  'en-GB': {
    'logo-essential': '£130',
    'logo-contest': '£249',
  },
  'fr-FR': {
    'logo-essential': '149 €',
    'logo-contest': '289 €',
  },
  'fr-CA': {
    'logo-essential': '199 $',
    'logo-contest': '409 $',
  },
  'fr-CH': {
    'logo-essential': '149 CHF',
    'logo-contest': '279 CHF',
  },
  'de-DE': {
    'logo-essential': '149 €',
    'logo-contest': '289 €',
  },
  'en-CA': {
    'logo-essential': '$199',
    'logo-contest': '$409',
  },
  'en-NZ': {
    // 99d does not support New Zealand: Revert to AUD
    'logo-essential': '$227 AUD',
    'logo-contest': '$449 AUD',
  },
  'en-SG': {
    'logo-essential': '$210',
    'logo-contest': '$409',
  },
  'en-IE': {
    'logo-essential': '149 €',
    'logo-contest': '289 €',
  },
  'de-AT': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'de-CH': {
    'logo-essential': 'CHF 149',
    'logo-contest': 'CHF 279',
  },
  'en-IN': {
    'logo-essential': '$149',
    'logo-contest': '$449',
  },
  'it-IT': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'nl-NL': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'nl-BE': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'fr-BE': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'es-ES': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'es-US': {
    'logo-essential': '$149',
    'logo-contest': '$299',
  },
  'it-CH': {
    'logo-essential': 'CHF 149',
    'logo-contest': 'CHF 279',
  },
  'pt-PT': {
    'logo-essential': '€ 149',
    'logo-contest': '€ 289',
  },
  'nb-NO': {
    'logo-essential': 'NOK 1,550',
    'logo-contest': 'NOK 2,999',
  },
  'da-DK': {
    'logo-essential': '1.110 kr.',
    'logo-contest': '2.099 kr.',
  },
  'sv-SE': {
    // 99d does not support Sweden: Revert to USD
    'logo-essential': '$149 USD',
    'logo-contest': '$299 USD',
  },
  'fi-FI': {
    // 99d does not support Finland: Revert to USD
    'logo-essential': '$149 USD',
    'logo-contest': '$299 USD',
  },
}

export const useLogoProductTilesConfig = (
  trackingCategory: string,
  locale: Locale
): LogoProductTilesConfig => {
  const { t } = useTranslation()

  return {
    logomaker: {
      title: t('Vista Logos > Logo Product Tile > Logomaker.productTileHeader'),
      description: t('Vista Logos > Logo Product Tile > Logomaker.productTileSubHeader'),
      imageDefault: {
        src: t('Vista Logos > Logo Product Tile > Logomaker.productImage.src'),
        altText: '',
      },
      linkHref: t('Vista Logos > Logo Product Tile > Logomaker.url.contentfulUrl'),
      ctaText: t('Vista Logos > Logo Product Tile > Logomaker.urlText'),
      priceText: t('Vista Logos > Logo Product Tile > Logomaker > Price Text.value'),
      ctaRef: useTrackNavigationClicked(
        trackingCategory,
        'Logo Product Tiles',
        t('Vista Logos > Logo Product Tile > Logomaker.url.contentfulUrl'),
        'createYourFreeLogo'
      ),
    },
    'logo-essential': {
      title: t('Vista Logos > Logo Product Tile > Logo Essential.productTileHeader'),
      description: t('Vista Logos > Logo Product Tile > Logo Essential.productTileSubHeader'),
      imageDefault: {
        src: t('Vista Logos > Logo Product Tile > Logo Essential.productImage.src'),
        altText: '',
      },
      linkHref: t('Vista Logos > Logo Product Tile > Logo Essential.url.contentfulUrl'),
      ctaText: t('Vista Logos > Logo Product Tile > Logo Essential.urlText'),
      priceText: t('Vista Logos > Logo Product Tile > Logo Essential > Price Text.value', {
        logoEssentialPriceAmount: logoProductsPricing[locale]['logo-essential'],
      }),
      ctaRef: useTrackNavigationClicked(
        trackingCategory,
        'Logo Product Tiles',
        t('Vista Logos > Logo Product Tile > Logo Essential.url.contentfulUrl'),
        'seePackageDetails'
      ),
    },
    'logo-contest': {
      title: t('Vista Logos > Logo Product Tile > Logo Contest.productTileHeader'),
      description: t('Vista Logos > Logo Product Tile > Logo Contest.productTileSubHeader'),
      imageDefault: {
        src: t('Vista Logos > Logo Product Tile > Logo Contest.productImage.src'),
        altText: '',
      },
      linkHref: t('Vista Logos > Logo Product Tile > Logo Contest.url.contentfulUrl'),
      ctaText: t('Vista Logos > Logo Product Tile > Logo Contest.urlText'),
      priceText: t('Vista Logos > Logo Product Tile > Logo Contest > Price Text.value', {
        logoContestBronzePriceAmount: logoProductsPricing[locale]['logo-contest'],
      }),
      ctaRef: useTrackNavigationClicked(
        trackingCategory,
        'Logo Product Tiles',
        t('Vista Logos > Logo Product Tile > Logo Contest.url.contentfulUrl'),
        'seeLogoContestDetails'
      ),
    },
  }
}
