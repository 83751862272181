import { useTranslation } from 'react-i18next'
import { BoundedContent, Column, FlexBox, GridContainer, H2, P, Row } from '@vp/swan'
import { useTrackLink } from '../../../../utils/analytics'
import EditWrapper from '../../../../components/EditWrapper/EditWrapper'
import useIsEditMode from '../../../../utils/useIsEditMode'
import ImageCard from './ImageCard/ImageCard'

const DigitalMarketing = () => {
  const { t } = useTranslation()
  const isEditMode = useIsEditMode()
  const linkRefBrandKit = useTrackLink('Vista Logos Click Tracking', {
    label: 'learn-more',
    location: 'brand-kit',
  })
  const linkRefWix = useTrackLink('Vista Logos Click Tracking', {
    label: 'get-started',
    location: 'wix-website-builder',
  })
  const linkRefSocialMediaTemplates = useTrackLink('Vista Logos Click Tracking', {
    label: 'create-in-minutes',
    location: 'social-media-templates',
  })

  return (
    <BoundedContent marginBottom='between-sections'>
      <FlexBox flexDirection='column'>
        <H2 fontSkin='title-section'>
          {t('Vista logos > product tile headings.tileTitle')}
        </H2>
        <P>{t('Vista logos > product tile headings.tileDescription')}</P>
        <GridContainer marginTop='between-subsections'>
          <Row style={{ margin: 'auto -12px' }}>
            {(t('Vista logos > tile 1 > brandkit.productTileHeader') !== '-1' ||
                            isEditMode) && (
                              <Column span={4}>
                                <EditWrapper url={t('Vista logos > tile 1 > brandkit.editUrl')}>
                                  <ImageCard
                                    linkRef={linkRefBrandKit}
                                    title={t(
                                      'Vista logos > tile 1 > brandkit.productTileHeader'
                                    )}
                                    description={t(
                                      'Vista logos > tile 1 > brandkit.productTileSubHeader'
                                    )}
                                    buttonText={t('Vista logos > tile 1 > brandkit.urlText')}
                                    image={t(
                                      'Vista logos > tile 1 > brandkit.productImage.src'
                                    )}
                                    href={t(
                                      'Vista logos > tile 1 > brandkit.url.contentfulUrl'
                                    )}
                                  />
                                </EditWrapper>
                              </Column>
            )}
            {(t('Vista logos > tile 2 > wix website.productTileHeader') !== '-1' ||
                            isEditMode) && (
                              <Column span={4}>
                                <EditWrapper url={t('Vista logos > tile 2 > wix website.editUrl')}>
                                  <ImageCard
                                    linkRef={linkRefWix}
                                    title={t(
                                      'Vista logos > tile 2 > wix website.productTileHeader'
                                    )}
                                    description={t(
                                      'Vista logos > tile 2 > wix website.productTileSubHeader'
                                    )}
                                    buttonText={t('Vista logos > tile 2 > wix website.urlText')}
                                    image={t(
                                      'Vista logos > tile 2 > wix website.productImage.src'
                                    )}
                                    href={t(
                                      'Vista logos > tile 2 > wix website.url.contentfulUrl'
                                    )}
                                  />
                                </EditWrapper>
                              </Column>
            )}
            {(t('Vista logos > tile 3 > vista create.productTileHeader') !== '-1' ||
                            isEditMode) && (
                              <Column span={4}>
                                <EditWrapper url={t('Vista logos > tile 3 > vista create.editUrl')}>
                                  <ImageCard
                                    linkRef={linkRefSocialMediaTemplates}
                                    title={t(
                                      'Vista logos > tile 3 > vista create.productTileHeader'
                                    )}
                                    description={t(
                                      'Vista logos > tile 3 > vista create.productTileSubHeader'
                                    )}
                                    buttonText={t(
                                      'Vista logos > tile 3 > vista create.urlText'
                                    )}
                                    image={t(
                                      'Vista logos > tile 3 > vista create.productImage.src'
                                    )}
                                    href={t(
                                      'Vista logos > tile 3 > vista create.url.contentfulUrl'
                                    )}
                                  />
                                </EditWrapper>
                              </Column>
            )}
          </Row>
        </GridContainer>
      </FlexBox>
    </BoundedContent>
  )
}

export default DigitalMarketing
